import { createTheme } from "@mui/material/styles";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import { yellow, red, grey } from "@mui/material/colors";
import { Grid, InputBase } from "@mui/material";
export const theme = createTheme({
  palette: {
    badgeColor: {
      main: "#f33",
      light: "#E9DB5D",
      dark: "#161616",
      contrastText: "#242105",
      secondary: "#ff3333",
    },
  },
});

export const ColorButtonRed = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText(red[500]),
  backgroundColor: "#e52727",
  "&:hover": {
    backgroundColor: "#b92929",
  },
}));

export const ColorButtonYellow = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText(yellow[500]),
  minWidth: "120px",
  backgroundColor: yellow[500],
  "&:hover": {
    backgroundColor: yellow[600],
  },
}));
export const ColorButtonGrey = styled(Button)(({ theme }) => ({
  color: grey[900],
  backgroundColor: grey[200],
  "&:hover": {
    backgroundColor: grey[300],
  },
  fontSize: "12px",
  fontWeight: 500,
}));
export const CenteredGrid = styled(Grid)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));
export const BootstrapInput = styled(InputBase)(
  ({ theme, from = "#fafafa" }) => ({
    "label + &": {
      marginTop: theme.spacing(3),
    },
    "& .MuiInputBase-input": {
      borderRadius: 4,
      position: "relative",
      backgroundColor: theme.palette.background.paper,
      border: `1px solid ${from}`,
      fontSize: 16,
      padding: "10px 26px 10px 12px",
      transition: theme.transitions.create(["border-color", "box-shadow"]),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
      "&:focus": {
        borderRadius: 4,
        borderColor: "#aaa8a8",
        boxShadow: "0 0 0 0.2rem #fafafa",
      },
    },
  })
);
