import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
// MUI Imports

import Badge from "@mui/material/Badge";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import Stack from "@mui/material/Stack";
import { useMediaQuery, Dialog } from "@mui/material";

// Custom Imports
import "../NavBar-Desktop/NavBar.css";
import RedPartLogoSVGDesktop from "../../../../svg/RedPartLogoSVGDesktop";
import { ColorButtonYellow } from "../../../../Themes/Theme";
import Login from "../../../Account/Login/Login";
// eslint-disable-next-line
import { useDispatch, useSelector } from "react-redux";
import {
  GetManufacturerList,
  GetProductDetails,
  GetProductWithCode,
  selectedMfgCleanUp,
  setdataCleanUp,
  setselectedMfg,
  setvehicleFinderObjCleanUp,
} from "../../../../Redux/Reducers/reducerSlices/ProductsSlices/ProductDetailsSlice";
import HeaderMenu from "../../HeaderMenu/HeaderMenu";
import { getLSD } from "../../../../Redux/Reducers/reducerSlices/AddToCartSlice/AddtoCartSlice";
import {
  loginPopUpOpen,
  popup,
} from "../../../../Redux/Reducers/reducerSlices/componentActionsSlices/ComponentActionsSlice";
import { useEffect } from "react";
export default function NavBarMidContent() {
  //store
  const [anchorEl, setAnchorEl] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [show, setShow] = useState(false);
  const [type, setType] = useState("");
  const [selectedOption, setSelectedOption] = useState(1);
  const [manufacturerData, setManufacturerData] = useState();
  // extra vars
  const isLargeScreen = useMediaQuery("(min-width:1300px)");
  const open = Boolean(anchorEl);
  const id = open ? "centered-popover" : undefined;
  const navigate = useNavigate();
  // redux get data
  const dispatch = useDispatch();
  const isOpen = useSelector((state) => state.ComponentAction.isOpenHeader);
  const userData = useSelector((state) => state.login?.data[0]);
  const productsData = useSelector((state) => state.ProductDetails?.data) || [];
  const Mdata = useSelector((state) => state.ProductDetails.Mdata);
  const isLoading = useSelector((state) => state.ProductDetails.isLoading);
  const cartProducts = useSelector((state) => state.addToCart.cartProducts);
  const { loginPopUp } = useSelector((state) => state.ComponentAction);
  const count = cartProducts.length;
  useEffect(() => {
    setManufacturerData(Mdata);
  }, [Mdata, cartProducts]);
  //functions
  const converter = (price, quantity) => {
    const priceString = price?.replace(/[^\d.]/g, "");
    const priceValue = parseFloat(priceString) * quantity;
    return priceValue;
  };
  const totalPrice = cartProducts
    .reduce((s, i) => s + converter(i?.retailPrice, i?.quantity), 0)
    .toFixed(2);
  React.useEffect(() => {
    dispatch(loginPopUpOpen(true));
  });
  React.useEffect(() => {
    // console.log("calling useeffect, products card");
    if (!loginPopUp) handleClose();
    if (isOpen) handleClose();
    const handleClickOutside = (event) => {
      if (anchorEl && !anchorEl.contains(event.target)) {
        handleClose();
      }
    };

    window.addEventListener("click", handleClickOutside);

    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, [anchorEl, isOpen, loginPopUp]);

  const handleClick = (event) => {
    dispatch(popup(false, "header"));
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const filteredProducts = productsData
    ? Mdata.filter(
        (product) =>
          product.partManufacturer
            ?.toLowerCase()
            .includes(searchTerm.toLowerCase()) ||
          product.productcode
            ?.toLowerCase()
            .includes(searchTerm.toLowerCase()) ||
          product.manufacturer?.toLowerCase().includes(searchTerm.toLowerCase())
      )
    : manufacturerData;

  const handleSuggestionClick = (value) => {
    setShow(false);
    setSuggestions(value);

    setSearchTerm(value.manufacturer);
    // console.log(value);
  };

  const handleSelect = (e) => {
    setShow(false);
    setSelectedOption();
    if (e.target.value === "1") {
      setType("1");
    } else if (e.target.value === "2") {
      setType("2");
    } else if (e.target.value === "3") {
      setType("3");
    } else if (e.target.value === "4") {
      setShow(true);
      dispatch(GetManufacturerList());
      setType("4");
    } else {
      setSearchTerm("");
    }
  };

  const search = async () => {
    dispatch(setdataCleanUp());
    dispatch(selectedMfgCleanUp());
    dispatch(setvehicleFinderObjCleanUp());
    setShow(false);
    if (type === "1") {
      dispatch(
        GetProductDetails({
          SearchInput: searchTerm,
          type: 5,
          userID: userData?.id || null,
        })
      );
    } else if (type === "2") {
      dispatch(
        GetProductDetails({
          SearchInput: searchTerm,
          type: 2,
          userID: userData?.id || null,
        })
      );
    } else if (type === "3") {
      // console.log(searchTerm);
      dispatch(
        GetProductWithCode({
          SearchInput: searchTerm,
          userID: userData?.id || null,
        })
      );
    } else if (type === "4") {
      dispatch(
        setselectedMfg(suggestions?.manufacturer || searchTerm?.toUpperCase())
      );
      dispatch(
        GetProductDetails({
          SearchInput: suggestions?.manufacturer || searchTerm?.toUpperCase(),
          type: 4,
          userID: userData?.id || null,
        })
      );
    }
    if (!isLoading) {
      navigate("/ProductView");
    }
  };
  const handleSearchTerm = (e) => {
    setSearchTerm(e.target.value);
    if (type === "4") setShow(true);
  };
  // console.log(Mdata);
  return (
    <div style={{ margin: "0 80px", position: "sticky" }}>
      <div style={{ height: "75px" }}>
        <div
          className="row"
          style={{
            height: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div
            className={isLargeScreen ? "col-2.5" : "col-1"}
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignContent: "center",
            }}
          >
            <div
              onClick={() => navigate("/")}
              style={{ alignSelf: "center", cursor: "pointer" }}
            >
              <RedPartLogoSVGDesktop />
            </div>
          </div>
          <div
            className={isLargeScreen ? "col-7" : "col-5"}
            style={{ display: "flex", flexGrow: "1" }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                width: "100%",
              }}
            >
              <select
                onChange={(e) => {
                  handleSelect(e);
                }}
                className="NBM-dropdown"
              >
                <option defaultValue={selectedOption}>Search By...</option>
                <option value={1}>Everything</option>
                <option value={2}>Engine code </option>
                <option value={3}>Manufacturers Part Code</option>
                <option value={4}>Manufacturer</option>
              </select>
              {/* <ColorButtonYellow
                className="NBM-ColorButtonText"
                startIcon={<DirectionsCarIcon />}
              >
                Vechile Finder
              </ColorButtonYellow> */}
              <input
                disabled={isLoading}
                style={{
                  marginLeft: "20px",
                  marginRight: "20px",
                  minWidth: "250px",
                }}
                className="NBM-Search-Box" //NBM refers to Nav bar mid
                placeholder={
                  isLoading ? "Loading..." : "Enter Keyword or Part Number"
                }
                value={searchTerm}
                onChange={(e) => {
                  handleSearchTerm(e);
                }}
              />
              <ColorButtonYellow
                onClick={search}
                className="NBM-ColorButtonText"
                startIcon={<DirectionsCarIcon />}
                disabled={isLoading}
              >
                {isLoading ? "Loading...." : "Search"}
              </ColorButtonYellow>
              {show && searchTerm && (
                <ul className="autosuggest-list">
                  {filteredProducts.map((suggestion, index) => (
                    <li
                      className="suggestion-item"
                      key={index}
                      onClick={(e) => handleSuggestionClick(suggestion)}
                      style={{ cursor: "pointer", padding: "5px" }}
                    >
                      {suggestion?.productcode}
                      {suggestion?.partManufacturer}
                      {suggestion?.manufacturer}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>
          <div
            className={isLargeScreen ? "col-3.5" : "col-4.5"}
            style={{ display: "flex" }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                width: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  width: "100%",
                }}
              >
                {/* <div className="NBM-Div-Hover">
                  <Badge
                    sx={{
                      "& .MuiBadge-badge": {
                        color: "#fff",
                      },
                    }}
                    color="badgeColor"
                    badgeContent={0}
                    showZero
                  >
                    <FavoriteBorderIcon
                      style={{ height: "40px", width: "40px" }}
                    />
                  </Badge>
                </div> */}
                <div className="NBM-Div-Hover" onClick={handleClick}>
                  <Stack direction="row">
                    <PermIdentityIcon
                      style={{ height: "40px", width: "40px" }}
                    />
                    <Stack spacing={0.1}>
                      <p className="NBM-Login-text">
                        {userData
                          ? userData?.firstName + " " + userData?.lastName
                          : "Hello, Log In"}
                      </p>
                      <h5 className="NBM-Login-ACCOUNT">My Account</h5>
                    </Stack>
                  </Stack>
                  <Dialog
                    open={open}
                    onClose={isOpen}
                    maxWidth={"sm"}
                    PaperProps={{
                      style: {
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      },
                    }}
                  >
                    <div
                      className="popover-input-main"
                      onClick={(e) => e.stopPropagation()}
                    >
                      <Login />
                    </div>
                  </Dialog>
                </div>
                <div
                  className="NBM-Div-Hover"
                  onClick={() => {
                    navigate("/CartDisplay");
                  }}
                >
                  <Stack direction="row">
                    <Badge
                      sx={{
                        "& .MuiBadge-badge": {
                          color: "#fff",
                        },
                      }}
                      color="badgeColor"
                      overlap="rectangular"
                      showZero
                      badgeContent={count}
                    >
                      <ShoppingCartOutlinedIcon
                        style={{ height: "40px", width: "40px" }}
                      />
                    </Badge>
                    <Stack spacing={0.1} pl={2}>
                      <p className="NBM-Login-text">Shopping Cart </p>
                      <h5 className="NBM-Login-ACCOUNT">
                        £{`${totalPrice || 0.0}`}
                      </h5>
                    </Stack>
                  </Stack>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
