import React from "react";

export default function RedPartLogoSVGDesktop() {
  return (
    <img
      src="https://www.ignitioncarparts.co.uk/images/logo.png"
      alt="logo"
      style={{ width: "128px", height: "60px" }}
    />
  );
}
